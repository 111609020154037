import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default function AboutUsModal({ showModal, setShowModal }) {
  return (
    <>
      {showModal && (
        <>
          <div className="mx-4 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-3xl font-semibold py-2 px-2">
                    About this space
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      <FontAwesomeIcon
                        icon={faClose}
                        className="text-xl"
                        style={{ color: "#000080" }}
                      />
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 pb-6 flex-auto overflow-y-auto h-96">
                  <p className="mb-3">
                    The property is Sri Lanka Tourist Board-approved and
                    consists of four sections totalling 59 sq m: a sitting area,
                    washroom, bedroom, and kitchen/pantry area.
                  </p>
                  <p className="my-3">
                    The unit is fully furnished, consisting of ceiling fans in
                    every section and an A/C in the bedroom. The bed is
                    queen-sized. The kitchen has a teak table for four, a
                    fridge, a gas cooker, an induction cooker, a rice cooker, an
                    electric kettle, and pantry cupboards with a sink. The
                    toilet is wall-to-wall tiled. A shower cubicle with modern
                    hot water connections.
                  </p>
                  <p className="mt-6 font-bold">The space</p>
                  <p>
                    The property is Sri Lanka Tourist Board-approved. Is part of
                    a large house consisting of 4 separate, identical annexes.
                    Two downstairs and two upstairs.
                  </p>
                  <p>
                    The upstairs has a separate gate entrance from the road, and
                    the downstairs stairs have another separate gate from the
                    road.
                  </p>
                  <p>
                    The property on offer has been totally refurbished this year
                    and is located on the ground floor with a garden area in the
                    front and back.
                  </p>
                  <p>
                    The two units on the ground floor have separately facing
                    entrances, providing all the privacy one requires.
                  </p>
                  <p>
                    At the moment, the other unit on the ground floor is not
                    offered for rent.
                  </p>
                  <p className="mb-3">
                    The property is a 15-minute walk from the main bus route.
                    Tuk-tuks are available on the main road.
                  </p>
                  <p className="my-3">
                    The neighbourhood consists of all villa type residential
                    houses with 20' road access.
                  </p>

                  <p className="mt-6 font-bold">Guest access</p>
                  <p className="mb-3">
                    Access to the property located on the ground floor is by a
                    gate from the 20-foot road. The main door is separate from
                    the front garden. The keys will be handed over upon my
                    arrival. Similarly, the keys are to be handed over to me on
                    departure.
                  </p>
                  <p className="my-3">
                    The gate and the main door are watched by a CCTV camera.
                  </p>

                  <p className="mt-6 font-bold">Other things to note</p>
                  <p>
                    My place is now Sri Lanka Tourist Board-approved. The total
                    area is 59 square meters. The height of the ceiling is 9
                    feet.
                  </p>
                  <p className="mb-3">
                    The square areas on individual sections are as follows:
                  </p>
                  <p className="mt-3">Living room: 17 sq m</p>
                  <p>Wash room: 6.3 sq m</p>
                  <p>Bed room: 16 sq m</p>
                  <p>Kitchen: 13 sq m</p>
                  <p className="my-3">
                    During an electricity power failure, a battery power backup
                    is available for lighting and ceiling fans, which comes on
                    automatically through an inverter and deep cycle battery
                    system.
                  </p>
                  <p className="my-3">
                    A water backup tank is available for uninterrupted water
                    supply.
                  </p>
                  <p className="mt-3">
                    The rear garden area and roofed area adjoining the kitchen
                    are available for drying cloths with a line.
                  </p>
                  <p>
                    Additionally, a drying clothes rack is placed in the
                    washroom.
                  </p>
                  <p className="mb-3">
                    For cooking purposes, I have provided a gas cooker. An
                    electric induction plate can be provided on request.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
