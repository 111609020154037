import React from "react";

const Fullscreen = ({ children, ...props }) => {
  const containerStyle = {
    backgroundColor: props.backgroundColor,
    maxWidth: "100vw",
    minHeight: props.minHeight ? props.minHeight : "100vh",
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop: 0,
    alignContent: props.alignContent ? props.alignContent : "top",
  };
  return <div style={containerStyle}>{children}</div>;
};
export default Fullscreen;
