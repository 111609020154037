import React, { useState } from "react";
import Fullscreen from "./Fullscreen";
import Modal from "./Modal";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import { faPersonCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faPersonCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { faPeopleCarryBox } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import { faHouseCircleCheck } from "@fortawesome/free-solid-svg-icons";

const TermsSection = () => {
  const [showModal, setShowModal] = useState(false);

  const terms = [
    {
      icon: faCalendarDays,
      title: "Minimum 3 days and over",
    },
    {
      icon: faFileInvoiceDollar,
      title: "Stays over 7 days, charges could be negotiated",
    },
    {
      icon: faCalendarXmark,
      title: "Any cancellation should be done prior to 3 days",
    },
    {
      icon: faPersonCirclePlus,
      title: "Check in before 1800hrs",
    },
    {
      icon: faPersonCircleMinus,
      title: "Check out prior to 1700hrs",
    },
    {
      icon: faPeopleCarryBox,
      title: "Accommodation for two adults",
    },
    {
      icon: faMoneyCheck,
      title: "Prior payment is preferred, if not to be mutually agreed",
    },
    {
      icon: faHouseCircleCheck,
      title: "House rules apply",
    },
  ];
  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <div
        className="items-center h-full w-screen pb-12 md:pt-0 pt-12"
        id="TermsSection"
      >
        {/* Page Heading and Logo */}
        <div className="md:flex md:px-28 px-10 py-4">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="h-16"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
          <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3">
            Booking terms and conditions
          </p>
        </div>
        <div className="grid gap-3 pt-5">
          {terms.map((term, index) => (
            <div
              className="grid grid-cols-5 bg-white drop-shadow-xl lg:mx-96 md:mx-16 mx-4 md:py-3 py-1 rounded-xl"
              key={index}
            >
              <div className="col-span-1 flex justify-center">
                <FontAwesomeIcon
                  icon={term.icon}
                  className="text-xl"
                  style={{ color: "#000080" }}
                />
              </div>
              <div className="col-span-4">
                <p>{term.title}</p>
              </div>
            </div>
          ))}
          <button
            className="lg:mx-96 md:mx-16 mx-4 md:py-3 text-white font-bold uppercase text-sm px-6 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            style={{ backgroundColor: "#000080" }}
            type="button"
            onClick={() => setShowModal(true)}
          >
            Check Rates
          </button>
        </div>
        {showModal && (
          <Modal showModal={showModal} setShowModal={setShowModal} />
        )}
      </div>
    </Fullscreen>
  );
};

export default TermsSection;
