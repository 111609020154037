import React, { useEffect, useState } from "react";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const NavBar = ({ currentSection }) => {
  // Navbar background color based on scroll position
  const navbarColor = window.scrollY < 10 ? "rgba(0, 0, 128, 0)" : "#000080";

  const [menuOpen, setMenuOpen] = useState(false);
  const [largeScreen, setLargeScreen] = useState(window.innerWidth >= 1024);

  // State to track whether the navbar should be visible
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    // Function to handle mouse move
    const handleMouseMove = (event) => {
      // Check if the mouse is close to the top of the browser window
      setIsVisible(event.clientY < 55 || window.scrollY < 10);
    };

    // Add event listener for mouse move
    window.addEventListener("mousemove", handleMouseMove);

    // Clean up event listener
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handleClick = (anchor) => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <div
      className={`navbar ${isVisible || !largeScreen ? "visible" : "hidden"}`}
    >
      <div
        className="flex place-content-top fixed top-0 w-screen h-fit z-20 text-white justify-center animate-navbar-animation"
        style={{ backgroundColor: navbarColor }}
      >
        <img
          src={anchorage_rental_house_colombo_srilanka_logo}
          className="md:h-20 h-16 py-2"
          alt="A&N anchorage logo"
        />
        <h1
          className="py-6 md:text-xl text-lg font-bold pl-2 md:pr-48 pr-24"
          style={{ textShadow: "0px 0px 3px #000000" }}
        >
          A&N Anchorage
        </h1>
        {/* Large screen navigation - horizontally */}
        {largeScreen && (
          <div className="py-6 w-screen lg:w-auto lg:flex lg:gap-4 sm:hidden">
            <a
              href="/#HomeSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("HomeSection");
              }}
              className={`px-4 z-10 ${
                currentSection === "HomeSection"
                  ? "underline underline-offset-8"
                  : ""
              }`}
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              Home
            </a>
            <a
              href="/#AboutUsSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("AboutUsSection");
              }}
              className="px-4 z-10"
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              About Us
            </a>
            <a
              href="/#GallerySection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("GallerySection");
              }}
              className="px-4 z-10"
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              Gallery
            </a>
            <a
              href="/#FacilitiesSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("FacilitiesSection");
              }}
              className="px-4 z-10"
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              Facilities
            </a>
            {/* <a
            href="/#ReviewsSection"
            onClick={(e) => {
              e.preventDefault();
              handleClick("ReviewsSection");
            }}
            className="px-4 z-10"
            style={{ textShadow: "0px 0px 3px #000000" }}
          >
            Reviews
          </a> */}
            <a
              href="/#TermsSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("TermsSection");
              }}
              className="px-4 z-10"
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              Terms
            </a>
            <a
              href="/#ContactsSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("ContactsSection");
              }}
              className="px-4 z-10"
              style={{ textShadow: "0px 0px 3px #000000" }}
            >
              Contacts
            </a>
          </div>
        )}

        {/* Medium/Small screen navigation */}
        {/* Display the icon only on screens smaller than large screens */}
        {!menuOpen && (
          <FontAwesomeIcon
            icon={faBars}
            className="py-4 text-3xl lg:hidden md:pl-32" // Hide on large screens
            style={{ color: "#FFFFFF" }}
            onClick={() => setMenuOpen(!menuOpen)}
          />
        )}

        {/* Display menu items vertically when the icon is clicked */}
        {menuOpen && (
          <>
            <FontAwesomeIcon
              icon={faXmark}
              className="py-4 text-3xl lg:hidden z-20 md:pl-32" // Hide on large screens
              style={{ color: "#FFFFFF" }}
              onClick={() => setMenuOpen(!menuOpen)}
            />
            <div className="absolute top-0 right-0 bg-black bg-opacity-90 w-screen h-screen flex justify-center items-center">
              <div className="flex flex-col gap-4">
                <a
                  href="/#HomeSection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("HomeSection");
                  }}
                  className="text-white"
                >
                  Home
                </a>
                <a
                  href="/#AboutUsSection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("AboutUsSection");
                  }}
                  className="text-white"
                >
                  About Us
                </a>
                <a
                  href="/#GallerySection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("GallerySection");
                  }}
                  className="text-white"
                >
                  Gallery
                </a>
                <a
                  href="/#FacilitiesSection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("FacilitiesSection");
                  }}
                  className="text-white"
                >
                  Facilities
                </a>
                {/* <a
              href="/#ReviewsSection"
              onClick={(e) => {
                e.preventDefault();
                handleClick("ReviewsSection");
              }}
              className="text-white"
            >
              Reviews
            </a> */}
                <a
                  href="/#TermsSection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("TermsSection");
                  }}
                  className="text-white"
                >
                  Terms
                </a>
                <a
                  href="/#ContactsSection"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("ContactsSection");
                  }}
                  className="text-white"
                >
                  Contacts
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
