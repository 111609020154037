import React from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import anchorage_rental_house_colombo_srilanka_registration from "../assets/anchorage_rental_house_colombo_srilanka_registration.jpeg";

const TourismRegistrationSection = () => {
  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <div
        className="items-center h-full w-screen pb-12 md:pt-0 pt-12"
        id="TourismRegistrationSection"
      >
        {/* Page Heading and Logo */}
        <div className="md:flex md:px-28 px-10 py-4 ">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="h-16"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
          <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3">
            Sri Lanka Tourism Registration
          </p>
        </div>

        <div className="flex justify-center md:px-28 px-10">
          <img
            src={anchorage_rental_house_colombo_srilanka_registration}
            alt="A&N anchorage rental house colombo sri lanka - Registration"
          />
        </div>
      </div>
    </Fullscreen>
  );
};

export default TourismRegistrationSection;
