import React, { useState } from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_image5 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image5.jpeg";
import anchorage_rental_house_colombo_srilanka_image11 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image11.jpeg";
import NavBar from "./NavBar";

const HomeSection = () => {
  const largeScreen = window.innerWidth >= 1024;

  // Scroll to Contacts
  const clickContacts = () => {
    const element = document.getElementById("ContactsSection");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <Fullscreen
      backgroundColor="#f5f5f1"
      backgroundImage={
        largeScreen
          ? anchorage_rental_house_colombo_srilanka_image11
          : anchorage_rental_house_colombo_srilanka_image5
      }
    >
      <NavBar currentSection="HomeSection" />

      {/* Text infront of the image */}
      <div
        className="grid place-content-center absolute top-0 w-screen h-full z-10 md:px-64 px-2 text-white"
        id="HomeSection"
      >
        <h1
          className="md:text-5xl text-3xl font-bold text-center pb-6 pt-8"
          style={{ textShadow: "2px 2px 8px #000000" }}
        >
          Explore Tropical Sri Lanka : Rent Your Ideal Vacation House in Colombo
        </h1>
        <h2
          className="md:text-4xl text-xl text-center pb-6"
          style={{ textShadow: "2px 2px 8px #000000" }}
        >
          Indulge in Sri Lankan Hospitality with Flexible Longer Visas for Every
          Budget
        </h2>
        <p
          className="md:text-3xl text-lg font-bold text-center pt-6 py-8"
          style={{ textShadow: "2px 2px 8px #000000" }}
        >
          Let's talk
        </p>

        <button
          className="font-bold py-4 px-8 rounded-3xl m-auto uppercase"
          style={{ backgroundColor: "#000080" }}
          onClick={(e) => {
            e.preventDefault();
            clickContacts();
          }}
        >
          Contact Us
        </button>
      </div>
    </Fullscreen>
  );
};

export default HomeSection;
