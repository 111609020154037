import React, { useState } from "react";
import Fullscreen from "./Fullscreen";
import image1 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image1.jpeg";
import image2 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image2.jpeg";
import image3 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image3.jpeg";
import image4 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image4.jpeg";
import image5 from "../assets/gallery/anchorage_rental_house_colombo_srilanka_image5.jpeg";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const GalleryOutdoorSection = () => {
  const imageArray = [
    { name: image3, description: "Colombo Vacation Rental House" },
    // { name: image5, description: "Houses to rent in sri lanka" },
    // { name: image1, description: "Annex for rent in colombo" },
    { name: image2, description: "Sri lanka rent house" },
    {
      name: image4,
      description: "Small house for rent in colombo",
    },
  ];
  const [largeScreen, setLargeScreen] = useState(window.innerWidth >= 1024);
  const [mediumScreen, setMediumScreen] = useState(
    window.innerWidth < 1024 && window.innerWidth >= 768
  );

  // ---------------FOR LARGE SCREENS---------------

  // Slice the image array into chunks
  const chunks = [];
  for (let i = 0; i < imageArray.length; i += 2) {
    chunks.push(imageArray.slice(i, i + 2));
  }

  // Select image
  const [selectedImage, setSelectedImage] = useState(image3);

  //  --------------FOR SMALL SCREENS----------------

  // State to track the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  // Handle next slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === imageArray.length - 1 ? 0 : prevSlide + 1
    );
  };
  // Handle previous slide
  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? imageArray.length - 1 : prevSlide - 1
    );
  };

  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <div className=" py-8" id="GallerySection">
        {/* Page Heading and Logo */}
        <div className="md:flex md:px-28 px-10">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="h-16"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
          <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3 ">
            Gallery - Outdoor
          </p>
        </div>
        <div className="grid justify-center items-center h-full">
          {largeScreen && (
            <div className="grid md:grid-cols-3 gap-6 md:px-28 px-10 lg:py-4 py-5">
              <div className="col-span-2">
                <img
                  src={selectedImage}
                  alt="rental house in Colombo, Sri Lanka"
                  className="rounded-xl"
                  style={{ height: "550px", width: "800px" }}
                />
              </div>
              <div className="grid grid-cols-1 gap-1">
                {/* Map over chunks and render each chunk within a separate grid*/}
                {chunks.map((chunk, index) => (
                  <div key={index} className="grid gap-0">
                    {chunk.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.name}
                        alt={image.description}
                        className={`rounded-xl ${
                          selectedImage === image.name ? "md:border-4" : ""
                        }`}
                        style={{
                          borderColor: "#000080",
                          height: "180px",
                          width: "180px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedImage(image.name);
                          console.log(selectedImage);
                        }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!largeScreen && (
            <div className="carousel py-2">
              {/* Buttons for previous and next slide */}
              <div className="absolute grid items-center h-full left-0 z-20">
                <button onClick={prevSlide}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="p-2 md:text-4xl text-xl "
                    style={{ color: "#000080" }}
                  />
                </button>
              </div>
              <div className="absolute grid items-center h-full right-0 z-20">
                <button onClick={nextSlide}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="p-2 md:text-4xl text-xl"
                    style={{ color: "#000080" }}
                  />
                </button>
              </div>
              {imageArray.map((image, index) => (
                <div
                  key={index}
                  style={{
                    display: index === currentSlide ? "block" : "none",
                  }}
                >
                  <img
                    key={index}
                    src={image.name}
                    alt={image.description}
                    className="rounded-xl "
                    style={{
                      height: mediumScreen ? "600px" : "300px",
                      width: mediumScreen ? "600px" : "300px",
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Fullscreen>
  );
};

export default GalleryOutdoorSection;
