import React, { useState } from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import booking__logo from "../assets/links/booking_logo.png";
import airbnb_logo from "../assets/links/airbnb_logo.png";
import NavBar from "./NavBar";
import AboutUsModal from "./AboutUsModal";

const AboutUsSection = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <NavBar />
      <div className="grid justify-center items-center h-full w-screen">
        <div
          className="grid lg:gap-6 gap-2 py-5 md:px-28 px-10 w-screen"
          id="AboutUsSection"
        >
          {/* About Us - Text */}
          <div className="pt-0">
            {/* Page Heading and Logo */}
            <div className="md:flex pb-4">
              <img
                src={anchorage_rental_house_colombo_srilanka_logo}
                className="h-16"
                alt="A&N anchorage rental house colombo sri lanka - logo"
              />
              <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3">
                Blissful Haven in Nawala
              </p>
            </div>

            <p className="lg:pb-2 pb-4">
              Introducing our cozy rental house located in the Nawala-Koswatte
              area of the Colombo district, Sri Lanka. Nawala is a renowned
              residential neighborhood, offering easy access to top tourist
              spots in Colombo, government establishments, and hospitals.
            </p>
            <p className="lg:pb-2 pb-4">
              By booking this property, you'll enjoy fully furnished
              accommodation with all the privacy you need away from a busy city
              accommodation.
            </p>
            <p className="lg:pb-2 pb-4">
              With a fully equipped kitchen, you'll have the freedom to cook
              your own meals and savor local delicacies, allowing you to enjoy
              the authentic Sri Lankan cuisine within your budget during your
              extended stay.
            </p>

            <p className="lg:pb-2 pb-4">
              Accessible via a 20-foot road, this ground-floor unit features
              separate entrances and garden spaces in both the front and back
              yards, ensuring comfort and accessibility for our guests.
            </p>
            <p className="lg:pb-2 pb-4">
              Take advantage of the new tourist visas, granting a longer stay
              while enjoying the tropical weather and a peaceful environment
              with significant cost savings.
            </p>
            <p className="lg:pb-2 pb-4">
              Don't miss out on this opportunity for a peaceful and
              budget-friendly stay in the heart of Colombo's lively community.
            </p>
            <button
              className="my-3 md:py-3 py-3 px-6 text-white font-bold uppercase text-sm rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              style={{ backgroundColor: "#000080" }}
              type="button"
              onClick={() => setShowModal(true)}
            >
              Read More
            </button>
            {showModal && (
              <AboutUsModal showModal={showModal} setShowModal={setShowModal} />
            )}
          </div>
        </div>

        {/* Links of advertisements in other platforms */}
        <div className="bg-black bg-opacity-10 md:p-5 p-3 justify-end mb-5 w-screen">
          <div className="flex md:flex-row flex-col justify-center items-center text-center md:gap-16 gap-4">
            <a href="https://www.booking.com/Pulse-d2f9oP" target="_blank">
              <img
                src={booking__logo}
                alt="booking.com colombo sri lanka"
                className="h-12"
              />
            </a>
            <a href="https://abnb.me/nobrHylgJIb" target="_blank">
              <img
                src={airbnb_logo}
                alt="airbnb sri lanka colombo"
                className="h-9 pb-1"
              />
            </a>
            <a href="https://www.srilanka.travel/" target="_blank">
              <h2 className="text-lg font-bold" style={{ color: "#000080" }}>
                Sri Lanka Tourism
              </h2>
            </a>
            <a href="https://www.srilankaevisa.lk/" target="_blank">
              <h2 className="text-lg font-bold" style={{ color: "#000080" }}>
                Sri Lanka eVisa
              </h2>
            </a>
            <a
              href="https://seatreservation.railway.gov.lk/mtktwebslr/"
              target="_blank"
            >
              <h2 className="text-lg font-bold " style={{ color: "#000080" }}>
                Sri Lanka Railway Booking Platform
              </h2>
            </a>
          </div>
        </div>
      </div>
    </Fullscreen>
  );
};

export default AboutUsSection;
