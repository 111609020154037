import React from "react";
import Fullscreen from "./Fullscreen";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingLock } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faTaxi } from "@fortawesome/free-solid-svg-icons";
import { faSquareParking } from "@fortawesome/free-solid-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import { faKitchenSet } from "@fortawesome/free-solid-svg-icons";
import { faCouch } from "@fortawesome/free-solid-svg-icons";
import { faBattery } from "@fortawesome/free-solid-svg-icons";

const FacilitiesSection = () => {
  const facilities = [
    {
      icon: faBuildingLock,
      title: "Secure Area",
      description:
        "The property is located amidst private residences, primarily villas, contributing to a secure and peaceful environment.",
    },
    {
      icon: faLocationDot,
      title: "Convenient Location",
      description:
        "Situated just a 10-minute walk from the Nawala-Rajagiriya main bus route, our property offers easy access to public transportation.",
    },
    {
      icon: faTaxi,
      title: "Transportation Services",
      description:
        "Numerous Tuk Tuk taxis, as well as prominent ride-hailing services such as Uber and PickMe, serve the area, providing reliable transportation options..",
    },
    {
      icon: faSquareParking,
      title: "Parking Availability",
      description:
        "Ample parking space is available on-site, providing residents with convenient and secure parking facilities.",
    },
    {
      icon: faStore,
      title: "Nearby Amenities",
      description:
        "Within walking distance, residents can find grocery stores, retail outlets, and restaurants, including well-known supermarkets like Keells and Cargills.",
    },
    {
      icon: faHospital,
      title: "Accessible Healthcare",
      description:
        "Two private hospitals are available within a 15-minute drive. The Colombo General Hospital is approximately 40 minutes away.",
    },
    {
      icon: faKitchenSet,
      title: "Contemporary Amenities",
      description:
        "The kitchen is equipped with a gas cooker, fridge, rice cooker, water heater, and cutlery. There is also a dining table with four chairs and pantry cupboards with a wash basin. Ceiling fans are fixed in the sitting area, bedroom, and kitchen.",
    },
    {
      icon: faCouch,
      title: "Furnished Accommodation",
      description:
        "The unit is newly renovated and consists of a furnished sitting area with a TV and WiFi connection, a comfortable bedroom with air conditioning, and a fully tiled toilet with a hot water shower cubicle.",
    },
    {
      icon: faBattery,
      title: "Extras for a Smooth Stay",
      description:
        "Electric failures are backed up by inverter battery pack connections. \n Continuous water connections with an overhead tank. \n CCTV camera at the gate. \n CO2 fire extinguisher is placed in the kitchen.",
    },
  ];

  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <div
        className="grid justify-center items-center h-full py-12"
        id="FacilitiesSection"
      >
        {/* Page Heading and Logo */}
        <div className="md:flex pb-4 md:px-28 px-10">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="h-16"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
          <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3">
            What We Offer
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-4 md:px-28 px-10 lg:py-2">
          {facilities.map((facility, index) => (
            <div className="grid md:grid-cols-6 items-center" key={index}>
              <div className="col-span-1 flex justify-center">
                <FontAwesomeIcon
                  icon={facility.icon}
                  className="p-2 text-3xl border-4 rounded-xl"
                  style={{ color: "#000080", borderColor: "#000080" }}
                />
              </div>
              <div className="col-span-5">
                <p className="font-bold">{facility.title}</p>
                <p style={{ whiteSpace: "pre-line" }}>{facility.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fullscreen>
  );
};

export default FacilitiesSection;
