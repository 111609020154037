import React from "react";
import anchorage_rental_house_colombo_srilanka_logo from "../assets/anchorage_rental_house_colombo_srilanka_logo.png";
import Fullscreen from "./Fullscreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import guest1 from "../assets/reviews/guest1.jpg";
import guest2 from "../assets/reviews/guest2.jpg";
import guest3 from "../assets/reviews/guest3.jpg";

const ReviewsSection = () => {
  // Displayed Reviews
  const reviews = [
    {
      name: "Natacha",
      country: "Sintra, Portugal",
      photo:
        "https://a0.muscache.com/im/pictures/user/User-79049788/original/e919dcd5-00f5-47dc-8f08-5977c0c2607c.jpeg?im_w=240",
      rating: 5,
      content:
        "We had a delightful stay at this Airbnb in Colombo, Sri Lanka, located in a safe, charming neighborhood. The serenity of the area offered a peaceful escape from the city bustle. The house was well-equipped with a fully-stocked kitchen, including a rice cooker and essential supplies, making it easy to feel at home. While the terrace was too hot to enjoy, the air-conditioned interiors with stylish decor and cozy sofas provided a comfortable retreat. The living room featured a TV with a streaming box and reliable internet for enjoyable evenings. The bathroom was well-appointed with necessary amenities and the bedroom boasted a very comfortable bed, ensuring restful nights. This Airbnb is an excellent choice for anyone seeking comfort and convenience in Colombo.",
      link: "https://www.airbnb.co.uk/rooms/1101882574760698919/reviews?adults=1&viralityEntryPoint=1&s=76&unique_share_id=623EC90A-A0D9-4901-8498-197D275331CF&_branch_match_id=1307735786021661995&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT0zKS9LLTdXPy08q8qjMSffyTAIAou4pbBsAAAA%3D&source_impression_id=p3_1715952869_nuJ0TVFEfY4P3Iwe&scroll_to_review=1154187498286231952",
    },
    {
      name: "Murtaza",
      country: "Dubai, United Arab Emirates",
      photo:
        "https://a0.muscache.com/im/pictures/user/User-378468684/original/610d7b08-24d5-46d9-a688-5ca31338a227.jpeg?im_w=240",
      rating: 5,
      content:
        "I had a wonderful stay at A&N Anchorage. The host was exceptionally polite, helpful, and informative, making us feel incredibly welcome. The house itself was absolutely beautiful, and we felt right at home. It was a truly enjoyable experience, and I would highly recommend it to anyone looking for a lovely place to stay.",
      link: "https://www.airbnb.co.uk/rooms/1101882574760698919/reviews?adults=1&viralityEntryPoint=1&s=76&unique_share_id=623EC90A-A0D9-4901-8498-197D275331CF&_branch_match_id=1307735786021661995&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT0zKS9LLTdXPy08q8qjMSffyTAIAou4pbBsAAAA%3D&source_impression_id=p3_1715952869_nuJ0TVFEfY4P3Iwe&scroll_to_review=1154187498286231952",
    },
  ];
  return (
    <Fullscreen backgroundColor="#f5f5f1" minHeight="fit-content">
      <div className="grid items-center h-full pb-12">
        <div className="md:flex pb-4 md:px-28 px-10">
          <img
            src={anchorage_rental_house_colombo_srilanka_logo}
            className="h-16"
            alt="A&N anchorage rental house colombo sri lanka - logo"
          />
          <p className="lg:text-4xl text-3xl font-bold py-3 md:pl-3">
            What Our Guests Say
          </p>
        </div>

        <div className="md:px-28 px-10 md:py-12">
          <div
            className="flex lg:flex-row flex-col items-center justify-center gap-4"
            style={{ height: "100%" }}
          >
            {/* Map over the reviews array */}
            {reviews.map((review, index) => (
              <div
                key={index}
                className="rounded-xl p-5 grid md:w-3/4 lg:w-auto xl:w-auto lg:h-96 xl:h-full items-center"
                style={{
                  backgroundColor: "#FFECAE",
                }}
              >
                <a href={review.link} target="_blank">
                  <p>{review.content}</p>
                  <div className="flex lg:flex-row flex-col pt-4">
                    <div className="md:pr-4">
                      <img
                        src={review.photo}
                        alt={`guest_${index + 1}_photo`}
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "30px",
                          alignSelf: "center",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className=" md:pl-0 pl-2">
                      <p className="font-bold ">{review.name}</p>
                      <p>{review.country}</p>
                      <div className="flex items-center">
                        {/* Render star icons based on rating */}
                        {[...Array(review.rating)].map((_, index) => (
                          <FontAwesomeIcon
                            key={index}
                            icon={faStar}
                            className="pt-1 pr-1"
                            style={{ color: "#000080" }}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fullscreen>
  );
};

export default ReviewsSection;
